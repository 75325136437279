<template>
  <div class="export">
    <b-button
      size="is-small"
      @click="exportAsCsv()"
    >
      Export
      <b-icon
        icon="file-csv"
        size="is-small"
        class="ml-1"
      />
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'Export',
  props: {
    gridApi: {
      type: Object,
      default: undefined,
    },
  },
  methods: {
    exportAsCsv() {
      if (this.gridApi) {
        this.gridApi.exportDataAsCsv({
          allColumns: true,
          skipRowGroups: false,
          fileName: 'by-apps.csv',
          shouldRowBeSkipped(params) {
            const { node } = params;
            const hasDefinedSubKey = (obj) => {
              if (typeof obj !== 'object' || obj === null) {
                return false;
              }

              return Object
                .keys(obj)
                .some((key) => obj[key] !== undefined);
            };

            const isEmptyRow = node.key
              && !hasDefinedSubKey(node.aggData);

            return isEmptyRow;
          },
          processCellCallback(params) {
            const { value, node, column } = params;
            if (!value && node.key) {
              if (column.colId === 'application') {
                return node.parent.key;
              }
              if (column.colId === 'country') {
                return 'Global';
              }
              return node.key;
            }
            return value ?? '';
          },
        });
      }
    },
  },
};
</script>

<style scoped>
  .export {
    display: inline-block;
  }
</style>
